import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import ApiManager from "../api/ApiManager";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {/* <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img src={require("../../assets/images/logo.svg")} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div> */}
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <div>
                {this.props.user &&
                  (this.props.user.lastName ? this.props.user.lastName : "") +
                    (this.props.user.firstName
                      ? this.props.user.firstName
                      : "")}
              </div>
              {/* <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="profile-image">
                      <img
                        src={require("../../assets/images/faces/face8.jpg")}
                        alt="profile"
                      />
                    </div>
                    <div className="text-left ml-3">
                      <p className="profile-name">Richard V.Welsh</p>
                      <small className="designation text-muted text-small">
                        Manager
                      </small>
                      <span className="status-indicator online"></span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item
                    className="dropdown-item p-0 preview-item d-flex align-items-center"
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    Manage Accounts
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    Check Inbox
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              {/* <button className="btn btn-success btn-block">
                New Project <i className="mdi mdi-plus"></i>
              </button> */}
            </div>
          </li>
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="fa fa-cutlery menu-icon"></i>
              <span className="menu-title">餐廳</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/user") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/user">
              <i className="fa fa-users menu-icon"></i>
              <span className="menu-title">用戶</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/transporter") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/transporter">
              <i className="fa fa-motorcycle menu-icon"></i>
              <span className="menu-title">外賣員</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/order") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/order"
              onClick={() => {
                this.setState({ newOrderCount: null });
                // this.markOrdersAsRead();
                if (window.location.pathname == "/order") {
                  window.location = window.location;
                }
              }}
            >
              <i className="fa fa-list menu-icon"></i>
              <span className="menu-title">訂單</span>
              {this.state.newOrderCount > 0 && (
                <span className="badge badge-danger">
                  {this.state.newOrderCount}
                </span>
              )}
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/shift") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/shift">
              <i className="fa fa-calendar menu-icon"></i>
              <span className="menu-title">編更</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/news") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/news">
              <i className="fa fa-newspaper-o menu-icon"></i>
              <span className="menu-title">最新消息</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/sms") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/sms">
              <i className="fa fa-mobile menu-icon"></i>
              <span className="menu-title">驗證碼</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/region") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/region">
              <i className="fa fa-map menu-icon"></i>
              <span className="menu-title">區域</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/district") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/district">
              <i className="fa fa-globe menu-icon"></i>
              <span className="menu-title">地區</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/setting") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/setting">
              <i className="fa fa-cog menu-icon"></i>
              <span className="menu-title">設置</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/coupon") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/coupon">
              <i className="fa fa-money menu-icon"></i>
              <span className="menu-title">優惠券</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/review") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/review">
              <i className="fa fa-star-half-o menu-icon"></i>
              <span className="menu-title">評價</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/surcharge") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/surcharge">
              <i className="fa fa-usd menu-icon"></i>
              <span className="menu-title">附加費</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    if ("Notification" in window) {
      Notification.requestPermission();
    }

    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    setInterval(this.fetchMoreOrders, 10000);
  }
  beep = () => {
    new Audio("/static/fireshort5s.mp3").play();
  };

  fetchMoreOrders = () => {
    ApiManager.getOrderList({ unreadOnly: true, perPage: 999 }).then(
      (result) => {
        const newOrderList = result.orderList;
        const newOrderCount = result.orderList.length;
        this.setState({ newOrderCount, newOrderList });
        if (result.orderList.length > 0) {
          if ("Notification" in window) {
            new Notification("有" + newOrderCount + "張新的訂單!");
          }
          this.beep();
        }
      }
    );
  };
  markOrdersAsRead = () => {
    let { newOrderList } = this.state;
    if (!newOrderList) {
      return;
    }
    const ids = newOrderList.map((o) => o.id);

    if (!ids || ids.length === 0) {
      return;
    }
    setTimeout(() => {
      ApiManager.standardPost("/admin/order/markAsRead", { ids });
    }, 5000);
  };
}

export default withRouter(Sidebar);
