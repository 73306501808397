import AppData from "../helpers/AppData";
const config = require("../../configs.json");

class ApiManager {
  /**
   * 標準錯誤碼控制，會顯示彈框，如果是user token錯會轉到login頁面
   */
  static standardErrorHandling() {
    return (jsonData) => {
      console.log(jsonData);
      if (jsonData.error) {
        if (jsonData.error === "NOT_AUTHENTICATED") {
          alert("請刷新並重新登入");
          Promise.all([
            AppData.removeValue("token"),
            AppData.removeValue("user"),
          ]).then(() => {
            window.location = "/user-pages/login";
          });
        } else if (jsonData.error === "INVALID_TOKEN") {
          //the token may have expired
          alert(
            "錯誤",
            "登入過期，請重新登入。",
            [
              {
                text: "OK",
                onPress: () => {
                  // history.push("/login");
                },
                style: "cancel",
              },
            ],
            { cancelable: false }
          );
          return new Promise((resolve) => resolve(jsonData));
        }
      }
      return new Promise((resolve) => resolve(jsonData));
    };
  }

  static standardCatch = () => {
    alert("網絡錯誤，請檢查網絡並再試。");
    return Promise.resolve({ success: false, error: "UNEXPECTED_ERROR" });
  };

  static baseUrl() {
    return config.apiUrl;
  }

  static login(data) {
    const path = ApiManager.baseUrl() + "/user/login";
    return ApiManager.fetchPost(path, data, false)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async logout() {
    const path = ApiManager.baseUrl() + "/user/logout";
    let token = await AppData.getValue("token");
    return ApiManager.fetchPost(path, null, token).then((response) =>
      response.json()
    );
  }
  static fullLogout() {
    return Promise.all([
      ApiManager.logout(),
      AppData.removeValue("token"),
      AppData.removeValue("user"),
    ]);
  }

  static async fetchPost(path, body = null, userToken = true) {
    if (userToken === true) {
      userToken = await AppData.getValue("token");
    }

    let headers = {
      Accept: "application/json",
    };

    if (userToken) {
      headers["Authorization"] = userToken;
    }

    if (body) {
      if (!(body instanceof FormData)) {
        body = JSON.stringify(body);
        headers["Content-Type"] = "application/json";
      }
    }
    return fetch(path, {
      method: "post",
      headers,
      body: body,
    });
  }

  static async standardPost(path, data, navigation) {
    return ApiManager.fetchPost(ApiManager.baseUrl() + path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static makeFormBody(data) {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
  }

  static async fetchGet(path, data = null, userToken = true) {
    if (userToken === true) {
      userToken = await AppData.getValue("token");
    }
    return fetch(
      data ? `${path + "?" + ApiManager.makeFormBody(data)}` : path,
      {
        method: "get",
        headers: userToken
          ? {
              Accept: "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: userToken,
            }
          : {
              Accept: "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
      }
    );
  }

  static async standardGet(path, data, navigation) {
    return ApiManager.fetchGet(ApiManager.baseUrl() + path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getShopList(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/shop/list";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getRegionDistricts(navigation) {
    const path = ApiManager.baseUrl() + "/admin/region/districts";
    return ApiManager.fetchGet(path)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getRegions(navigation) {
    const path = ApiManager.baseUrl() + "/admin/region/regions";
    return ApiManager.fetchGet(path)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }
  static async getShop(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/shop/detail";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getUser(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/user/detail";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .catch(ApiManager.standardCatch);
  }
  static async getTransporterList(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/transporter/list";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getTransporter(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/transporter/detail";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getOrderList(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/order/list";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getOrder(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/order/detail";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getShiftList(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/shift/list";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }

  static async getShift(data, navigation) {
    const path = ApiManager.baseUrl() + "/admin/shift/detail";
    return ApiManager.fetchGet(path, data)
      .then((response) => response.json())
      .then(ApiManager.standardErrorHandling())
      .catch(ApiManager.standardCatch);
  }
}

export default ApiManager;
