import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import "font-awesome/css/font-awesome.min.css";
import Spinner from "../app/shared/Spinner";

import AppData from "./helpers/AppData";

class App extends Component {
  state = { loaded: false };

  refreshStatus = () => {
    Promise.all([
      AppData.getValue("token"),
      AppData.getValue("user", true),
    ]).then(([token, user]) => {
      if (token) {
        console.log("user: ", user);
        this.setState({ isSignedIn: !!token, user });
      }
    });
  };

  checkLoginStatus = () => {
    AppData.getValue("token").then((value) => {
      if (value) {
        this.setState({ loaded: true, isSignedIn: true, user: value.user });
      } else {
        this.setState({ loaded: true, isSignedIn: false, user: null });
      }
    });
  };

  componentDidMount() {
    this.onRouteChanged();
    this.checkLoginStatus();

    this.listeners = [
      document.addEventListener("userLoggedIn", () => this.refreshStatus()),
      document.addEventListener("userLoggedOut", () => this.refreshStatus()),
    ];
  }

  componentWillUnmount() {
    this.listeners.forEach((listener) => {
      if (listener) {
        listener.remove();
      }
    });
  }

  render() {
    if (!this.state.loaded) {
      return <Spinner />;
    }
    let navbarComponent = !this.state.isFullPageLayout ? (
      <Navbar isSignedIn={this.state.isSignedIn} user={this.state.user} />
    ) : (
      ""
    );
    let sidebarComponent = !this.state.isFullPageLayout ? (
      <Sidebar user={this.state.user} />
    ) : (
      ""
    );
    // let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";

    return (
      <div className="container-scroller">
        {navbarComponent}
        <div className="container-fluid page-body-wrapper">
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes
                isSignedIn={this.state.isSignedIn}
                user={this.state.user}
              />
            </div>
            {/* {footerComponent} */}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/privacy",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
      }
    }
  }
}

export default withRouter(App);
