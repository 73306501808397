import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const EditShop = lazy(() => import("./dashboard/EditShop"));

const User = lazy(() => import("./user/User"));
const EditUser = lazy(() => import("./user/EditUser"));
const AddUser = lazy(() => import("./user/AddUser"));

const Transporter = lazy(() => import("./transporter/Transporter"));
const EditTransporter = lazy(() => import("./transporter/EditTransporter"));

const Order = lazy(() => import("./order/Order"));
const EditOrder = lazy(() => import("./order/EditOrder"));

const Shift = lazy(() => import("./shift/Shift"));
const AddShift = lazy(() => import("./shift/AddShift"));
const EditShift = lazy(() => import("./shift/EditShift"));

const News = lazy(() => import("./news/News"));
const AddNews = lazy(() => import("./news/AddNews"));
const EditNewsPosition = lazy(() => import("./news/EditNewsPosition"));

const Coupon = lazy(() => import("./coupon/Coupon"));
const EditCoupon = lazy(() => import("./coupon/EditCoupon"));
const EditUserCoupon = lazy(() => import("./coupon/EditUserCoupon"));
const Review = lazy(() => import("./review/Review"));

const Surcharge = lazy(() => import("./surcharge/Surcharge"));
const EditSurcharge = lazy(() => import("./surcharge/EditSurcharge"));

const Region = lazy(() => import("./region/Region"));
const District = lazy(() => import("./region/District"));

const Sms = lazy(() => import("./sms/Sms"));
const Setting = lazy(() => import("./settings/Setting"));
const EditSetting = lazy(() => import("./settings/EditSetting"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const FontAwesome = lazy(() => import("./icons/FontAwesome"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./user-pages/Error404"));
const Error500 = lazy(() => import("./user-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));

const BlankPage = lazy(() => import("./user-pages/BlankPage"));
const Privacy = lazy(() => import("./user-pages/Privacy"));

class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.isSignedIn) {
      return (
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/privacy" component={Privacy} />
            <Route path="/user-pages/login-1" component={Login} />
            <Redirect to="/user-pages/login-1" />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/editShop" component={EditShop} />

          <Route exact path="/user" component={User} />
          <Route exact path="/user/editUser" component={EditUser} />
          <Route exact path="/user/addUser" component={AddUser} />

          <Route exact path="/transporter" component={Transporter} />
          <Route
            exact
            path="/transporter/editTransporter"
            component={EditTransporter}
          />

          <Route exact path="/order" component={Order} />
          <Route exact path="/order/editOrder" component={EditOrder} />

          <Route exact path="/shift" component={Shift} />
          <Route exact path="/shift/addShift" component={AddShift} />
          <Route exact path="/shift/editShift" component={EditShift} />

          <Route exact path="/news" component={News} />
          <Route exact path="/news/addNews" component={AddNews} />
          <Route
            exact
            path="/news/editNewsPosition"
            component={EditNewsPosition}
          />

          <Route exact path="/coupon" component={Coupon} />
          <Route exact path="/coupon/editCoupon" component={EditCoupon} />
          <Route
            exact
            path="/coupon/editUserCoupon"
            component={EditUserCoupon}
          />
          <Route exact path="/review" component={Review} />
          <Route exact path="/surcharge" component={Surcharge} />
          <Route
            exact
            path="/surcharge/editSurcharge"
            component={EditSurcharge}
          />
          <Route exact path="/region" component={Region} />
          <Route exact path="/district" component={District} />
          <Route exact path="/sms" component={Sms} />
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/setting/editSetting" component={EditSetting} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/font-awesome" component={FontAwesome} />

          <Route path="/charts/chart-js" component={ChartJs} />

          <Route path="/user-pages/login-1" component={Login} />
          {/* <Route path="/user-pages/register-1" component={ Register1 } /> */}

          <Route path="/user-pages/error-404" component={Error404} />
          <Route path="/user-pages/error-500" component={Error500} />

          <Route path="/user-pages/blank-page" component={BlankPage} />
          <Route path="/privacy" component={Privacy} />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
