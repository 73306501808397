export default class AppData {

  static async getValue(key, needParse = false) {
    let result = await localStorage.getItem(key);
    if (needParse && result) {
      return JSON.parse(result);
    }
    return result;
  }
  static async setValue(key, value) {
    if (typeof value === 'object' && value) {
      value = JSON.stringify(value);
    }
    return await localStorage.setItem(key, value);
  }
  static async removeValue(key) {
    return await localStorage.removeItem(key);
  }
}
